<template>
    <div class="content">
        <router-link to='/admin/add'>
        <van-button round block type="primary">添加</van-button>
        </router-link>
        <div class="item" v-for="(item,index) in list" :key="index">
            <p>{{item.name}}</p>
               <van-button   type="primary" size="small" @click="goTo(item)">修改</van-button>
        </div>
    </div>
</template>

<script>
import { project } from "@/api/project";
export default {
    components: {},
    data() {
        return {
            list:[]
        };
    },
    computed: {},
    watch: {},
    methods: {
        goTo(item){
            this.$router.push({path:'/admin/add',query:{id:item.id}})
        }
    },
    created() {
        project().then(res=>{
            this.list =res.result;
        })
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.content{
    padding: 20px 10px;
    height: 100vh;
    background: #fff;
    .item{
        display: flex;
        margin: 10px 0;
        p{
            flex: 1;
        }
    }
}
</style>